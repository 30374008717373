import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bigcommerce-supermarket-theme-documentation"
    }}>{`BigCommerce Supermarket Theme Documentation`}</h1>
    <p>{`Theme designed and developed by `}<a parentName="p" {...{
        "href": "https://papathemes.com"
      }}>{`Papathemes`}</a>{` `}</p>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "get-started"
        }}>{`Get Started`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "customization"
        }}>{`Customization`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "faqs"
        }}>{`FAQs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "changelog"
        }}>{`Release Notes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://papathemes.com/contact-us/"
        }}>{`Contact for support`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      